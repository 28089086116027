import { useCallback, useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import { Box, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDialogButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";

import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "./candy-machine";
import { AlertState, formatNumber, getAtaForMint, toDate } from "./utils";
import { MintCountdown } from "./MintCountdown";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";

import nft1 from "./images/1.jpg";
import nft2 from "./images/2.jpg";
import nft3 from "./images/3.jpg";
import nft4 from "./images/4.jpg";
import nft5 from "./images/5.jpg";
import nft6 from "./images/6.jpg";
import nft7 from "./images/7.jpg";
import nft8 from "./images/8.jpg";
import nft9 from "./images/9.jpg";
import bg from "./images/bg.svg";
import bricks from "./images/bricks.png";

const MintContainer = styled.div`
  text-align: center;
  padding: 0 20px;
  max-wdth: 400px;
`;

const Card = styled.div`
  display: block;
  border: 1px solid rgba(75, 96, 141, 0.3);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 7px;
    top: -7px;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(75, 96, 141, 0.3);
    z-index: -1;
  }
`;

const ConnectButton = styled(WalletDialogButton)`
  background: transparent;
  color: #fff;
  border-color: rgba(255, 255, 252, 0.5);

  &:hover {
    background: #fff;
    color: #051537;
  }
`;

const CloseButton = styled(WalletDisconnectButton)`
  background: #fff;
  color: #051537;
  border-color: rgba(255, 255, 252, 1);
  padding: 0 20px;
  height: 40px;
  &:hover {
    background: rgba(255, 255, 252, 0.9);
  }
`;

const GreenButton = styled(WalletDialogButton)`
  background: #1ce7c2;
  border-color: #1ce7c2;
  color: #051537;

  &:hover {
    background: #fff;
    border-color: #fff;
    color: #051537;
  }
`;

const Line = styled.div`
  position: absolute;
  left: 36px;
  top: 116px;
  height: 100%;
  border-left: 1px solid #4b608d;
  display: none;

  &:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -8px;
    width: 16px;
    height: 3px;
    background: #1ce7c2;
  }

  @media (min-width: 992px) {
    display: block;
  }
`;
const SwiperContainer = styled.div`
  @media (min-width: 992px) {
    padding: 0 80px;
  }
`;

const Header = styled.div`
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media (min-width: 992px) {
    padding: 20px 30px 0 60px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 0 20px;
  padding-top: 70px;
  padding-bottom: 60px;
  text-align: center;

  @media (min-width: 992px) {
    padding-top: 170px;
    padding-bottom: 100px;
  }
`;

const BottomCircle = styled.div`
  position: absolute;
  left: 60px;
  top: 25%;
  width: calc(100% - 120px);
  height: 112px;
  border: 1px solid #4b608d;
  border-radius: 50%;
  pointerevents: none;
  z-index: -1;
`;

const Bgcircle = styled.img`
  position: absolute;
  bottom: -40%;
  left: 5%;
  width: 90%;
  pointerevents: none;
  z-index: -1;
  @media (min-width: 992px) {
    bottom: -55%;
  }
`;

const nfts = [
  {
    image: nft1,
  },
  {
    image: nft2,
  },
  {
    image: nft3,
  },
  {
    image: nft4,
  },
  {
    image: nft5,
  },
  {
    image: nft6,
  },
  {
    image: nft7,
  },
  {
    image: nft8,
  },
  {
    image: nft9,
  },
];

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  error?: string;
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [isActive, setIsActive] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();
  const cluster = props.network;
  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }
      if (props.error !== undefined) {
        setAlertState({
          open: true,
          message: props.error,
          severity: "error",
          hideDuration: null,
        });
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection
          );
          console.log("Candy machine state: ", cndy);
          let active = cndy?.state.goLiveDate
            ? cndy?.state.goLiveDate.toNumber() < new Date().getTime() / 1000
            : false;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            const limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                severity: "error",
                hideDuration: null,
              });
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                severity: "error",
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: "error",
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
          severity: "error",
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.error, props.rpcHost]
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {
      setIsUserMinting(true);
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: "Please sign account setup transaction",
            severity: "info",
          });
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                "Setup transaction succeeded! Please sign minting transaction",
              severity: "info",
            });
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: "Please sign minting transaction",
            severity: "info",
          });
        }

        const mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          const remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSetupTxn(undefined);
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
            hideDuration: 7000,
          });
          refreshCandyMachineState("processed");
        } else if (status && !status.err) {
          setAlertState({
            open: true,
            message:
              "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
            severity: "error",
            hideDuration: 8000,
          });
          refreshCandyMachineState();
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);
  console.log(wallet);
  return (
    <Box
      style={{
        minHeight: "100vh",
        padding: "0 0 100px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={bricks}
        alt=""
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          pointerEvents: "none",
          zIndex: -1,
        }}
      />
      <Line />
      <Header>
        <a href="https://jumpcrypto.com/">
          <svg
            width={79}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#a)" fill="#F9F7F4">
              <path d="M6.04 5.998h.905V19.86C6.945 22.929 4.57 25 0 25v-2.915c1.962 0 2.943-.818 2.943-2.481V5.998H6.04ZM3.38 3.746A2.161 2.161 0 0 1 2.723 2.2 2.144 2.144 0 0 1 3.381.65 2.183 2.183 0 0 1 4.944 0a2.2 2.2 0 0 1 1.562.652A2.161 2.161 0 0 1 7.165 2.2a2.144 2.144 0 0 1-.659 1.547 2.183 2.183 0 0 1-1.562.653 2.2 2.2 0 0 1-1.563-.653ZM18.532 5.998h4.002v13.12h-4.002V17.2a6.32 6.32 0 0 1-2.208 1.63 6.376 6.376 0 0 1-2.697.547c-2.996 0-4.984-1.841-4.984-5.294V6.001h4.003v6.573c0 2.273.904 3.427 2.738 3.427 1.756 0 3.15-1.356 3.15-4.042l-.002-5.961ZM47.508 11.044v8.08h-4.001v-6.7c0-2.2-.852-3.299-2.557-3.298-1.652 0-3.073 1.38-3.073 4.04v5.959h-4.002v-6.7c0-2.2-.852-3.3-2.556-3.299-1.652 0-3.072 1.38-3.072 4.04v5.959h-4.003V6.005h4.003v1.893c1.316-1.432 2.866-2.149 4.7-2.149 2.322 0 3.823.896 4.517 2.686 1.422-1.795 3.126-2.693 5.113-2.693 3.073 0 4.93 1.636 4.93 5.302ZM62.387 7.532c1.291 1.228 1.936 2.89 1.936 5.013 0 2.124-.645 3.788-1.936 4.988-1.29 1.227-2.918 1.84-4.906 1.84-1.756 0-3.176-.562-4.26-1.687v7.06h-4.002V5.997h4.001v1.41c1.085-1.124 2.505-1.687 4.261-1.688 1.988-.004 3.615.61 4.906 1.812Zm-2.246 5.013c0-2.352-1.472-3.913-3.46-3.913-1.962 0-3.46 1.483-3.46 3.913 0 2.43 1.498 3.914 3.46 3.914 1.988 0 3.46-1.56 3.46-3.914Z" />
            </g>
            <path d="M78.52 15.491v3.099H66.007V15.49H78.52Z" fill="#1CE7C2" />
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h64.323v25H0z" />
              </clipPath>
            </defs>
          </svg>
        </a>
        {!wallet.connected ? (
          <>
            <ConnectButton>
              .Connect Wallet{" "}
              <svg
                style={{ marginLeft: "8px" }}
                width={14}
                height={12}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.612 11.315 13.764 6 8.612.685l-1.077 1.11 3.307 3.411H0v1.587h10.842l-3.307 3.411 1.077 1.111Z"
                  fill="currentColor"
                />
              </svg>
            </ConnectButton>
          </>
        ) : (
          <CloseButton>Disconect Wallet</CloseButton>
        )}
      </Header>
      <TitleContainer>
        <Typography variant="h1" style={{ display: "inline-block" }}>
          Mint your <span style={{ color: "#1CE7C2" }}>Buffalo</span>
        </Typography>
      </TitleContainer>

      <Box style={{ position: "relative" }}>
        <SwiperContainer>
          <BottomCircle />
          <Swiper
            loop
            effect={"coverflow"}
            centeredSlides
            spaceBetween={20}
            slidesPerView={2}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Autoplay]}
            breakpoints={{
              1366: {
                slidesPerView: 4,
                spaceBetween: 120,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 80,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
          >
            {nfts.map((n, index) => (
              <SwiperSlide key={index} style={{ padding: "10px" }}>
                <Card>
                  <img
                    src={n.image}
                    style={{
                      display: "block",
                      width: "100%",
                    }}
                  />
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          <Bgcircle src={bg} alt="" />
        </SwiperContainer>
      </Box>

      <Box style={{ paddingTop: "36px", textAlign: "center" }}>
        {!wallet.connected ? (
          <GreenButton>
            .Mint NFT
            <svg
              style={{ marginLeft: "8px" }}
              width={14}
              height={12}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.612 11.315 13.764 6 8.612.685l-1.077 1.11 3.307 3.411H0v1.587h10.842l-3.307 3.411 1.077 1.111Z"
                fill="currentColor"
              />
            </svg>
          </GreenButton>
        ) : (
          <>
            <MintContainer>
              {candyMachine?.state.isActive &&
              candyMachine?.state.gatekeeper &&
              wallet.publicKey &&
              wallet.signTransaction ? (
                <GatewayProvider
                  wallet={{
                    publicKey:
                      wallet.publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                    //@ts-ignore
                    signTransaction: wallet.signTransaction,
                  }}
                  gatekeeperNetwork={
                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                  }
                  clusterUrl={rpcUrl}
                  cluster={cluster}
                  options={{ autoShowModal: false }}
                >
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    setIsMinting={(val) => setIsUserMinting(val)}
                    onMint={onMint}
                    isActive={
                      isActive ||
                      (isPresale && isWhitelistUser && isValidBalance)
                    }
                  />
                </GatewayProvider>
              ) : (
                <>
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    setIsMinting={(val) => setIsUserMinting(val)}
                    onMint={onMint}
                    isActive={
                      isActive ||
                      (isPresale && isWhitelistUser && isValidBalance)
                    }
                  />
                </>
              )}
            </MintContainer>
          </>
        )}
      </Box>

      {wallet.connected && candyMachine && (
        <Box
          style={{
            maxWidth: 200,
            margin: "0 auto",
            position: "absolute",
            right: 20,
            bottom: 20,
            textAlign: "right",
          }}
        >
          <Box>
            <Typography style={{ fontSize: 12 }}>
              Remaining {`${itemsRemaining}`}
            </Typography>
            <Typography style={{ fontSize: 12, display: "none" }}>
              {isWhitelistUser && discountPrice ? "Discount Price" : "Price"}{" "}
              {isWhitelistUser && discountPrice
                ? `◎ ${formatNumber.asNumber(discountPrice)}`
                : `◎ ${formatNumber.asNumber(candyMachine.state.price)}`}
            </Typography>
          </Box>
          <Box>
            {isActive && endDate && Date.now() < endDate.getTime() ? (
              <>
                <Typography
                  variant="caption"
                  align="center"
                  display="block"
                  style={{ fontWeight: "bold", fontSize: 12 }}
                >
                  TO END OF MINT
                </Typography>
              </>
            ) : (
              <>
                <MintCountdown
                  key="goLive"
                  date={getCountdownDate(candyMachine)}
                  style={{ justifyContent: "flex-end" }}
                  status={
                    candyMachine?.state?.isSoldOut ||
                    (endDate && Date.now() > endDate.getTime())
                      ? "COMPLETED"
                      : isPresale
                      ? "PRESALE"
                      : "LIVE"
                  }
                  onComplete={toggleMintButton}
                />
                {isPresale &&
                  candyMachine.state.goLiveDate &&
                  candyMachine.state.goLiveDate.toNumber() >
                    new Date().getTime() / 1000 && (
                    <Typography
                      variant="caption"
                      align="center"
                      display="block"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      UNTIL PUBLIC MINT
                    </Typography>
                  )}
              </>
            )}
          </Box>
        </Box>
      )}

      <Snackbar
        open={alertState.open}
        autoHideDuration={
          alertState.hideDuration === undefined ? 6000 : alertState.hideDuration
        }
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const getCountdownDate = (
  candyMachine: CandyMachineAccount
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? candyMachine.state.goLiveDate
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined
  );
};

export default Home;
